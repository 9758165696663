import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logoImg from '../../images/Bitcoin-Halvening-Axe-and-Coinstack.png'

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rebeccapurple`, //`#F2F1ED
      textAlign: `center`,
    }}
  >
    <div
      style={{
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        margin: `0 auto`,
        maxWidth: 960,
        maxHeight: 100,
        padding: `1rem 1.0875rem`,
      }}
    >
      <img
        className="halving-logo"
        src={logoImg}
        style={{width: '75px', height: '75px'}}
        alt="Bitcoin Halvening Axe and Coinstack by Yap.cx"
      />

      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            fontWeight: 900,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
